import { Mmsys112 } from "@react95/icons";
import { useState } from "react";
import Draggable from "react-draggable";
import YouTube from "react-youtube"
import { Button, Window, WindowContent, WindowHeader } from "react95"
const opts = {
    height: '390',
    width: '640',
    playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
    },
};

const VideoPlayerIcon = () => {
    const [open, setopen] = useState(false);
    const [select, setselected] = useState(null);
    const handleClick = () => {
        setopen(false);
    };
    return (
        <>
        <Draggable>
        <div className="w-[80px] m-5" onClick={() => setopen(true)} onDoubleClick={() => setopen(true)}>
          <figure
            className="w-20 text-white"
            onClick={() => {
              select ? setselected(false) : setselected(true);
            }}
          >
            <div className=" absolute w-20 h-[100px]"></div>
            <Mmsys112 className="relative w-10 left-5"/>
            <figcaption
              className={
                select
                  ? ` w-20 text-center bg-icon border-2 border-dotted`
                  : "  w-[70px] ml-[5px] mt-[3px] text-center"
              }
            >
              Chill Tube
            </figcaption>
          </figure>
        </div>
      </Draggable>
            {open && <Draggable>
                <Window>
                    <WindowHeader>
                        Chill
                        <div
                            className="font-bold absolute top-[4px] right-[10px]"
                            onClick={() => handleClick(false)}
                        >
                            <Button
                                style={{ height: "23px", width: "23px" }}
                                className="font-bold"
                            >
                                x
                            </Button>
                        </div>
                    </WindowHeader>
                    <WindowContent>
                        <YouTube videoId="jfKfPfyJRdk" opts={opts} />

                    </WindowContent>
                </Window>
            </Draggable>}
        </>
    )
};
export default VideoPlayerIcon;