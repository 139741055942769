import React from "react";
import {
  WindowContent,
  Table,
  TableDataCell,
  TableHeadCell,
  TableRow,
  TableBody,
  TableHead,
} from "react95";
const skill = [
  {
    name: "Python",
    img: "https://www.ai4business.it/wp-content/uploads/sites/11/2020/01/Python-logo.jpg",
    history: 8
  },
  {
    name: "Rust",
    img: "https://blog.logrocket.com/wp-content/uploads/2021/01/why-is-rust-popular.png",
    history: 4
  },
  {
    name: "Solidity",
    img: "https://i0.wp.com/metaschool.so/articles/wp-content/uploads/2022/05/Frame-740hello-world-smart-contract.png?resize=583%2C333&ssl=1",
    history: 6
  },
  {
    name: "Yul",
    img: "https://fuel-labs.ghost.io/content/images/size/w2000/2021/09/1_UODQtnszf0FhSwM7ZSEJ4Q.png",
    history: 4
  },
  {
    name: "Move",
    img: "https://codezeros-prod.s3.us-east-2.amazonaws.com/blogs/codeZerosfm0wreu61686374734207.jpg",
    history: 1
  },
  {
    name: "Swift",
    img: "https://cartt.ca/wp-content/uploads/2020/01/swift-logo.jpg",
    history: 2
  },
  {
    name: "C",
    img: "https://www.pngitem.com/pimgs/m/31-312155_c-programming-language-logo-hd-png-download.png",
    history: 3
  },
  {
    name: "C++",
    img: "https://logodix.com/logo/1137946.png",
    history: 3
  },
  {
    name: "TypeScript",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/4/4c/Typescript_logo_2020.svg/1200px-Typescript_logo_2020.svg.png",
    history: 5
  },
  {
    name: "JavaScript",
    img: "/jas.png",
    history: 6
  },
  {
    name: "Node.js",
    img: "/node.webp",
    history: 6
  },
  {
    name: "React",
    img: "/react.png",
    history: 5
  },
  {
    name: "Redux",
    img: "/redux.png",
    history: 5
  },
  {
    name: "AWS",
    img: "https://www.pngplay.com/wp-content/uploads/3/Amazon-Web-Services-AWS-Logo-Transparent-PNG.png",
    history: 5
  },
  {
    name: "Docker",
    img: "https://logos-world.net/wp-content/uploads/2021/02/Docker-Logo-2013-2015.png",
    history: 5
  },
  {
    name: "Kubernetes",
    img: "https://assets.website-files.com/5f10ed4c0ebf7221fb5661a5/5f639fdb1de15e42d31f987d_Kubernetes_Logo.png",
    history: 3
  },
  {
    name: "GraphQL",
    img: "https://i.pinimg.com/originals/66/ec/d4/66ecd45c7b6a7a76cd3c2c1e16b14ea0.png",
    history: 5
  },
  {
    name: "Sequelize",
    img: "https://cdn.iconscout.com/icon/free/png-256/free-sequelize-2-1175003.png",
    history: 4
  },
  {
    name: "Postgresql",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/2/29/Postgresql_elephant.svg/640px-Postgresql_elephant.svg.png",
    history: 4
  },
  {
    name: "Express",
    img: "https://www.mementotech.in/assets/images/icons/express.png",
    history: 5
  },
  {
    name: "Html",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/6/61/HTML5_logo_and_wordmark.svg/640px-HTML5_logo_and_wordmark.svg.png",
    history: 7
  },
  {
    name: "Css",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/CSS3_logo_and_wordmark.svg/1650px-CSS3_logo_and_wordmark.svg.png",
    history: 7
  },
  {
    name: "Tailwind",
    img: "https://upload.wikimedia.org/wikipedia/commons/thumb/d/d5/Tailwind_CSS_Logo.svg/2048px-Tailwind_CSS_Logo.svg.png",
    history: 4
  },
];
const Skills = () => {
  return (
    <WindowContent
      style={{ width: "700px", height: "350px", overflow: "scroll" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableHeadCell>Icon</TableHeadCell>
            <TableHeadCell>Name</TableHeadCell>
            <TableHeadCell style={{ width: "100px" }} disabled>
              Work Experience
            </TableHeadCell>
          </TableRow>
        </TableHead>
        <TableBody className="flex text-lg text-center">
          {skill.map((skills, i) => {
            return (
              <>
                <TableRow key={i}>
                  <TableDataCell style={{ textAlign: "center" }}>
                    <img
                      src={skills.img}
                      alt="skills"
                      className="my-[5px] ml-5"
                      width="45px"
                    />
                  </TableDataCell>
                  <TableDataCell>{skills.name}</TableDataCell>
                  <TableDataCell>{skills.history}</TableDataCell>
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </WindowContent>
  );
};

export default Skills;
