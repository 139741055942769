import React from "react";
import { useState } from "react";
import { Select, GroupBox, Button } from "react95";
import confetti from "canvas-confetti";

const options4 = [
  { value: 0, label: "Ninja" },
  { value: 1, label: "Guitarist" },
  { value: 2, label: "Potato" },
  { value: 3, label: "Fullstack Engineer" },
  { value: 4, label: "Fullmetal alchemist" },
  { value: 5, label: "Wizard" },
  { value: 6, label: "Lumberjack" },
];

const Me = () => {
  const [title, setTitle] = useState(null);
  const [msg, setMsg] = useState("");

  const handleClick = () => {
    if (title === 1 || title === 3 || title === 5 || title === 6) {
      confetti();
      setMsg("Correct!");
    }
    else {
      setMsg("Please Try again");
    } 
  };
  return (
    <div id="default-selects" className="ml-2">
      <GroupBox label="stats">
        <h2>Name</h2>
        <p>Jeremiah O. Nolet</p>
        <h2>Years Programming</h2>
        <p>9</p>
        <h2>Professional Years Programming</h2>
        <p>5</p>
        <h2>Patents</h2>
        <p>2</p>
        <h2>Title</h2>
        <Select
          onChange={(e) => setTitle(e.value)}
          style={{ margin: "0px 20px 10px 0px" }}
          options={options4}
          menuMaxHeight={160}
          width={280}
        ></Select>
        <Button onClick={handleClick}>Submit</Button>
        <span className="relative left-[50px]">{msg}</span>
      </GroupBox>
    </div>
  );
};

export default Me;
