import React, { useState } from "react";
import Draggable from "react-draggable";
import { Link } from "react-router-dom";
import {
  Window,
  WindowHeader,
  WindowContent,
  Tabs,
  TabBody,
  Button,
  Tab,
  ScrollView,
} from "react95";
import Me from "./Me";
import Skills from "./Skills";

const Info = ({ click }) => {
  const [state, setState] = useState({
    activeTab: 0,
  });

  const handleChange = (value) => setState({ activeTab: value });

  const { activeTab } = state;
  return (
    <Draggable>
      <div className="absolute top-20 left-[30rem]">
        <Window style={{ width: 800, height: 500 }}>
          <WindowHeader>
            Jeremiah Owen Nolet
            <div
              className="font-bold absolute top-[4px] right-[10px]"
              onClick={() => click(false)}
            >
              <Button
                style={{ height: "23px", width: "23px" }}
                className="font-bold"
              >
                x
              </Button>
            </div>
          </WindowHeader>

          <WindowContent>
            <Tabs value={activeTab} onChange={handleChange}>
              <Tab value={0}>Profile</Tab>
              <Tab value={3}>About</Tab>
              <Tab value={1}>Skills</Tab>
              <Tab value={2}>Projects</Tab>
            </Tabs>
            <TabBody style={{ height: 400 }}>
              {activeTab === 1 && <Skills></Skills>}
              {activeTab === 3 && (
                <div className="text-xl">
                  <ScrollView style={{ height: "350px" }}>

                  <div>
                    <p>
                      <strong>Early Years: </strong>
                      Originating from Bristol, Pennsylvania, my childhood was a
                      challenging yet formative experience. Despite adversities, I
                      fostered a deep passion for mathematics, winning statewide
                      contests and earning recognition from Ivy League institutions and
                      George School at the age of 12. Financial restrictions, however,
                      deterred me from pursuing these opportunities.
                    </p>
                    <div>
                      <p>
                        <strong>Transition to Workforce: </strong>
                        In response to escalating gang violence within the school
                        system, I left school at 17 to embark on a career as a
                        tree-climbing arborist. Concurrently, I attained my GED and
                        pursued higher education independently. I continued to utilize
                        the public library to my advantage from a youth and embarked on
                        a personal application of classical liberal arts while mimicing
                        the syllabus of college institutions.
                      </p>
                      <p>
                        In my youth, one of my personal interests and areas of study was
                        American Law. This interest led me to conduct an extensive
                        research project using historical legal materials dating back to
                        the early 1800s, housed at the Easton Public Library.
                        <a href="https://pharout_labs_pinata.mypinata.cloud/ipfs/QmaaK83J5VywgsewdypxqZvcyWF8Qtr5kHmTU3wocnmJFR">
                          This research culminated in a comprehensive thesis.{" "}
                        </a>
                        A significant portion of this work explores the Trivium and
                        Quadrivium methods of classical liberal arts. These
                        methodologies, which I discovered and applied from a young age,
                        significantly contributed to my autodidactic endeavors,
                        empowering me to expand my knowledge beyond traditional academic
                        disciplines.
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Entrepreneurial Venture: </strong>I took on a job as an
                        apprentice arborist at the age of 17 and was trained by a
                        lumberjack in the woods of Quakertown Township and also worked
                        at a local tree nursery. Charlie was hurt really bad on the job
                        one day and I had to help run his business after just being
                        taught to climb less than a year before. This experience forced
                        me into a world of customer relations and business management
                        that I hadn't been introduced yet to at the age of 19, and so
                        began the life-long study and application of the field of
                        business. I eventually moved to Bucks County, Pennsylvania and
                        took on a position as an arborist and farm hand for Harry
                        Bohleman, a board-certified master arborist of Pennsylvania. I
                        learned much from Harry and will always hold a special place in
                        my heart for those long days of cutting fields with old
                        1940-50's Mc'cormic and Allis Chamers tractors and shepherding
                        the herd on his 200+ acres of farm.
                      </p>
                      <p>
                        I moved on and eventually established my own tree-cutting
                        business and operated as a sub-contracting, traveling arborist
                        for companies up and down the north-east coast of the U.S. for
                        over a decade . Although as an arborist I spent the majority of
                        my weekdays working sometimes 13 hour days in the season, the
                        high pay and rather simple use of applied physics freed me to
                        pursue other things that I was interested in without succumbing
                        to any outstanding debt. From the age of 20 onwards, I have cut
                        out media and entertainment that was distracting or
                        manipulative, and focused on my interests of personal study and
                        work.
                      </p>
                      <p>
                        Concurrently through these years, I focused on fostering my
                        academic and professional interests, free from the distraction
                        of non-productive media. I ran a wood shop where I milled
                        rough-sawn lumber from locally sourced trees that I removed and
                        built into custom furniture. I also had an art studio where I
                        spent the majority of my free time creating music or art to sell
                        at local galleries. One of the things I truly admired about
                        Bucks County was their appreciation for local artists and plein
                        aire painters. I taught myself how to paint using the
                        disciplines of my youth, and you will find one of my pieces at
                        the bottom of this website of a stream in Tinicum Township,
                        Pennsylvania, in late fall during a misty morning near the
                        Delaware river.
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Exploration of Computer Sciences: </strong>
                        My relationship with computers began at an early age with an
                        apple p.c. and modem dial-up connection in my childhood home; we
                        eventually got a Dell. I still hold nostalgia for the old dial
                        tone of connecting to the internet in the early 90's. I always
                        had a P.C., or eventually laptop, and had built a few websites
                        in the early 2000's - 2010's using wordpress and other tools,
                        but mainly utilized it for my music endeavors and educational
                        research. Accessing YouTube in it's early growth stages was a
                        different environment than it is today, but there maintained
                        through it's expansion an open sourced style of educational
                        material shared such as college lectures or other advanced
                        learning material that gave access to a variety of material my
                        libraries could not. I began studying programming and computer
                        science concepts in my free time as an avenue of personal
                        interest. In line with my autodidactic approach to learning,
                        I've always prioritized grasping the foundational principles of
                        computer science and its accompanying mathematical requisites.
                        I've consistently found great pleasure in exploring areas like
                        cryptography and linear algebra, even from a young age,
                        maintaining notebooks for mental arithmetic practices and
                        mathematical proofs.
                      </p>

                      <p>
                        My journey in mathematics took an exhilarating turn in my early
                        twenties when I delved into the study of mathematical set
                        theory. The roots of my interest in this field can be traced
                        back to my passion for physics, specifically when I discovered
                        Kurt Gödel through an Albert Einstein biography. Gödel's
                        profound philosophies prompted a deep exploration into the world
                        of set theory. My interaction with the field of cryptocurrency,
                        which started in early 2015, was another milestone in my
                        academic pursuits. This exposure sparked a passion that has
                        relentlessly grown and evolved ever since. This lead me to
                        participate actively in crypto forums and communities.
                      </p>
                    </div>
                    <p>
                      <strong>Self-Education in Programming: </strong>I acquired a
                      certificate in HTML/CSS and nurtured a fascination for Python
                      early on. This interest in Python, combined with my love for
                      mathematics, led me to construct trading bots and ERC-20 indexers
                      for myself and others, and maintain remote nodes and servers using
                      NGINX and Apache. I took online classes on ML and Financial
                      Trading Algorithms in the NY Institute of Finance, and was an
                      early alumni of <a href="https://www.fast.ai/">FastAI</a>, by dr.
                      Jeremy Howard. Additionally, I developed a fondness for JavaScript
                      and eventually Typescript, using it daily. I have earned
                      certificates in Ethereum Development, Solidity, Yul, Advanced
                      Solidity Optimizations, Javascript, Typescript, Python, Rust, Git,
                      Data Structures & Algorithms and others, and continue to learn
                      daily while utilizing various languages such as Go, C#, C++, Java,
                      PHP and others as needed.
                    </p>
                    <div>
                      <p>
                        <strong>Community Involvement: </strong>
                        My role in the crypto community expanded as I managed Discord
                        and Telegram communities for ERC-20 projects and contributed to
                        research and development teams for various blockchain
                        initiatives. I was an active contributor as an advisor for Orion
                        Protocol in it's early developmental forms, and was an alpha
                        tester for projects such as Truebit and others. I became
                        involved with the Buidlers and Scaffold-Eth community 2 years
                        ago and actively help from time to time on their Telegram chats.
                      </p>
                      <p>
                        I am also a part of the Gun.js community. First & foremost, GUN
                        is a community of the nicest and most helpful people out there.
                        So <a href="http://chat.gun.eco/">I want to invite you</a> to go
                        tell Mark I said hello! The GUN ecosystem stack is a collection
                        of independent and modular tools covering everything from CRDT
                        conflict resolution, cryptographic security & encryption, radix
                        storage serialization, mesh networking & routing algorithms, to
                        distributed systems correctness & load testing, CPU scheduled
                        JSON parser to prevent UI lag, and more!
                      </p>
                    </div>
                    <div>
                      <p>
                        <strong>Professional Development: </strong>
                        My journey in professional software development involved
                        building full-stack web3 applications and accruing multiple
                        certificates in various programming languages and technologies.
                        This perpetual cycle of learning and growth has been a constant
                        over the past 3-4 years while I contracted for various
                        blockchain communities and investors.
                      </p>
                      <p>
                        In my last position, I contracted with a start-up company in
                        Portugal as a lead senior engineer developing a custom smart
                        contract generator for multiple blockchain languages, written in
                        Typescript. I did extensive research into the current blockchain
                        networks and languages,{" "}
                        <a href="https://eips.ethereum.org/">EIP proposals</a>, the{" "}
                        <a href="https://ethereum.org/en/developers/tutorials/yellow-paper-evm/">
                          Ethereum Yellow-Paper
                        </a>{" "}
                        and the{" "}
                        <a href="https://github.com/OpenZeppelin">
                          Open Zeppelin contracts
                        </a>
                        , in order to create a concise and current Intermediate
                        Representative object that could be used across the variety of
                        network languages and applications. I orchestrated Continuous
                        Integration and Continuous Delivery (CI/CD) pipelines using
                        GitHub workflows, effectively maintaining distinct branches for
                        development and production. Additionally, I encapsulated the
                        software within Docker containers for seamless management with
                        Kubernetes. I also led the integration of web3 libraries into
                        the Next.js frontend and the functionality of wallet connecting
                        and transactions.
                      </p>
                    </div>
                    <p>
                      <strong>Current Position:</strong>
                      My position at Viveel came to an abrupt halt after the latest
                      funding efforts were unsuccessful, and I found myself without a
                      job in mid-April, just after my birthday! I began working on
                      GPT-do-dah as a side project and eventually received an invitation
                      for an interview with a mysterious client towards the end of
                      April. The recruiter was reluctant to share much and they made the
                      description quite vague, only with the terms to be familiar with
                      the areas of Solidity, Javascript, Python and other languages, and
                      the web3, and not to be an immediate junior, and also to know who
                      NBC was. I thought to myself, what do you mean know who NBC was?
                      Who doesn't know who NBC is? It turned out to be an interview with
                      NBCUniversal! Now, I currently serve as a software developer under
                      contract at NBCUniversal! I look forward to the full term of my
                      contract and am extremely honored for such an opportunity. Truly
                      the culmination of many many years of discipline and study
                      invariably pursuing the same objective and finally attaining the
                      beginning of my journey! "If you believe it, you can achieve it!"
                    </p>
                  </div>
                  </ScrollView>
                </div>
              )}

              {activeTab === 0 && (
                <div className="flex justify-between">
                  <img alt="me" src="/me.jpeg" width={380}></img>
                  <Me></Me>
                </div>
              )}
              {activeTab === 2 && (
                  <ScrollView style={{ height: "350px", width: "100%" }}>
                <div className="flex justify-between w-[44.7rem]" style={{ margin: '10px'}}>

                  <Link
                    to={"https://www.gpt-tutor.me"}
                    target="blank"
                  >
                    <p className="px-[4.2rem]">gpt-tutor.me</p>

                    <div className=" w-[200px]  text-center h-[150px]">
                      <img alt="gpt-tutor-me" src="gpttutorme.png"></img>
                    </div>
                  </Link>
                  <Link
                    to={"https://pharout-swap.surge.sh/"}
                    target="blank"
                  >
                    <p className="px-[84px] ">Pharout Galaxy Swap</p>
                    <div className="text-center " style={{ margin: '10px'}}>
                      <img
                        className="relative top-3  w-[300px] h-[200px]"
                        alt="pharout-swap"
                        src="swap.png"
                      ></img>
                    </div>
                  </Link>

                  <Link
                    to={"https://eth-chess.surge.sh/"}
                    target="blank"
                  >
                    <span className="px-[75px]">EthChess</span>
                    <div className="text-center " style={{ margin: '10px'}}>
                      <img
                        className="relative top-3  w-[300px] h-[200px]"
                        alt="ethchess"
                        src="ethchess.png"
                      ></img>
                    </div>
                  </Link>
                </div>
                  </ScrollView>
              )}
            </TabBody>
          </WindowContent>
        </Window>
      </div>
    </Draggable>
  );
};

export default Info;
